import * as React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableRow,
    Paper,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableHead,
    Typography,
    Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { cDimensionValueReference } from '../../api/types';
import { t } from '@lingui/macro';
import LabeledTextField from '../labeledTextField';

export default function GenericGridTable({
    data,
    setData,
    type,
    header,
}: {
    data: cDimensionValueReference[],
    setData: (newData: cDimensionValueReference[]) => void,
    type:
    "basicList" |
    "extension" |
    "dimension" |
    "element" |
    "extensionStructure" |
    "onlyDimension" |
    "tagsDimensionValues" |
    "headerDimensionValues" |
    "labelDimensionValues" |
    "contextDimensionValues" |
    "childDimensionValues";
    header?: string;
}) {
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleDialog = () => setOpenDialog(!openDialog);

    const handleDeleteRow = (index: number) => {
        const newData = data.filter((_, i) => i !== index);
        setData(newData);
    };

    const handleAddDimensionValue = (dimensionValue: cDimensionValueReference) => {
        console.log(dimensionValue)
        setData([...data, dimensionValue]);
        handleDialog();
    };

    return (
        <Box>
            <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: "35vw" }}>
                    <TableHead>
                        {header && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    {header === "extension" ? (
                                        <>{t`Hierarchy`}</>
                                    ) : header === "extensionStructure" ? (
                                        <>{t`Structure`}</>
                                    ) : (
                                        <>{header}</>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={t`Add extension value`}>
                                        <AddIcon
                                            onClick={handleDialog}
                                            cursor='pointer'
                                        />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                        {!header && (
                            <>
                                {type === "basicList" && (
                                    <TableRow>
                                        <TableCell>{t`Url`}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={t`Add dimension value`}>
                                                <AddIcon
                                                    onClick={handleDialog}
                                                    cursor='pointer'
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(type === "dimension" || type === "onlyDimension") && (
                                    <TableRow>
                                        <TableCell>{t`Dimension`}</TableCell>
                                        <TableCell>{t`Dimension Name`}</TableCell>
                                        <TableCell>{t`Value Name`}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={t`Add dimension value`}>
                                                <AddIcon
                                                    onClick={handleDialog}
                                                    cursor='pointer'
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(type === "contextDimensionValues") && (
                                    <TableRow>
                                        <TableCell>{t`Dimension Name`}</TableCell>
                                        <TableCell>{t`Dimension`}</TableCell>
                                        <TableCell>{t`Consolidated`}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={t`Add dimension value`}>
                                                <AddIcon
                                                    onClick={handleDialog}
                                                    cursor='pointer'
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(type === "tagsDimensionValues") && (
                                    <TableRow>
                                        <TableCell>{t`Dimension Name`}</TableCell>
                                        <TableCell>{t`Dimension`}</TableCell>
                                        <TableCell>{t`Consolidated`}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={t`Add new dimension value`}>
                                                <AddIcon
                                                    onClick={handleDialog}
                                                    cursor='pointer'
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(type === "headerDimensionValues" || type === "labelDimensionValues") && (
                                    <TableRow>
                                        <TableCell>{t`Dimension Name`}</TableCell>
                                        <TableCell>{t`Dimension`}</TableCell>
                                        <TableCell>{t`Typed Member Value`}</TableCell>
                                        <TableCell>{t`Consolidated`}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={t`Add new dimension value`}>
                                                <AddIcon
                                                    onClick={handleDialog}
                                                    cursor='pointer'
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(type === "childDimensionValues") && (
                                    <TableRow>
                                        <TableCell>{t`Dimension Value`}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={t`Add new dimension value`}>
                                                <AddIcon
                                                    onClick={handleDialog}
                                                    cursor='pointer'
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {type === "element" && (
                                    <TableRow>
                                        <TableCell>{t`Additional Elements`}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={t`Add new element value`}>
                                                <AddIcon
                                                    onClick={handleDialog}
                                                    cursor='pointer'
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {type === "extension" && (
                                    <TableRow>
                                        <TableCell>{t`Structure`}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={t`Add new extension value`}>
                                                <AddIcon
                                                    onClick={handleDialog}
                                                    cursor='pointer'
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        )}
                    </TableHead>
                    {/* TODO need to make so that tableCell rows match the headers   */}
                    <TableBody sx={{ height: "50vh", "& tr": { maxHeight: "10px" } }}>
                        {data.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    cursor: "default",
                                    backgroundColor: (theme) =>
                                        index % 2 === 0 ? "white" : theme.palette.grey[200],
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.grey[400],
                                    },
                                }}
                            >
                                {type === "basicList" && (
                                    <>
                                        <TableCell>{row.dimensionValue.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )}
                                {(type === "dimension" || type === "onlyDimension") && (
                                    <>
                                        <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                        <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                        <TableCell>{row.dimensionValue.valueName}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )}
                                {type === "contextDimensionValues" && (
                                    <>
                                        <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                        <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                        <TableCell>{row.dimensionValue.onlyWhenConsolidated}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )}
                                {type === "tagsDimensionValues" && (
                                    <>
                                        <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                        <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                        <TableCell>{row.dimensionValue.onlyWhenConsolidated}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )}
                                {(type === "headerDimensionValues" || type === "labelDimensionValues") && (
                                    <>
                                        <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                        <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                        <TableCell>{row.typedMemberValue}</TableCell>
                                        <TableCell>{row.dimensionValue.onlyWhenConsolidated ? 'Yes' : 'No'}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )}
                                {type === "childDimensionValues" && (
                                    <>
                                        <TableCell>{row.dimensionValue.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )}
                                {type === "element" && (
                                    <>
                                        <TableCell>{row.dimensionValue.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )}
                                {type === "extension" && (
                                    <>
                                        <TableCell>{row.typedMemberValue}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                        {data.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <Typography>
                                            {type === "dimension" ? t`No dimension values` : type === "element" ? t`No element values` : t`No extension values`}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>

                    {(type === "dimension" || type === "extension" || type === "extensionStructure" || type === "headerDimensionValues" || type === "labelDimensionValues" || type === "basicList") && (
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {(type === "dimension" || type === "headerDimensionValues" || type === "labelDimensionValues") && (
                                            <LabeledTextField
                                                label={t`Override Typed Member`}
                                                fieldType='element'
                                                options={mockDimensionValueOptions}
                                            />
                                        )}
                                        {(type === "extensionStructure" || type === "extension" || type === "basicList") && (
                                            <LabeledTextField
                                                label={t`Custom Taxonomy Group`}
                                                fieldType='text'
                                            />
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>
            <DialogCustomDimensionValue
                open={openDialog}
                onClose={handleDialog}
                onAdd={handleAddDimensionValue}
                type={type}
            />
        </Box >
    );
}

const DialogCustomDimensionValue = ({
    open,
    onClose,
    onAdd,
    type,
}: {
    open: boolean;
    onClose: () => void;
    onAdd: (dimensionValue: cDimensionValueReference,
    ) => void;
    type:
    "basicList" |
    "extension" |
    "dimension" |
    "element" |
    "extensionStructure" |
    "onlyDimension" |
    "tagsDimensionValues" |
    "headerDimensionValues" |
    "labelDimensionValues" |
    "contextDimensionValues" |
    "childDimensionValues";
}) => {
    const [newDimensionValue, setNewDimensionValue] = React.useState<cDimensionValueReference>({
        id: '0',
        dimensionValue: {
            taxonomyValue: [],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: '',
            valueName: '',
            name: '',
        },
        typedMemberValue: null,
    }
    );


    const handleAddDimensionValue = () => {
        console.log('New dimension value:', newDimensionValue);
        onAdd(newDimensionValue);
        onClose();
    };

    const updateDimensionValue = (updatedFields: Partial<cDimensionValueReference['dimensionValue']>) => {
        setNewDimensionValue(prev => ({
            ...prev,
            dimensionValue: {
                ...prev.dimensionValue,
                ...updatedFields
            }
        }));
    };

    const handleFieldChangeEntireElement = (field: keyof cDimensionValueReference, value: cDimensionValueReference[keyof cDimensionValueReference]) => {
        if (field === 'dimensionValue') {
            updateDimensionValue(value as Partial<cDimensionValueReference['dimensionValue']>);
        } else {
            setNewDimensionValue(prev => ({
                ...prev,
                [field]: value as string | null
            }));
        }
    };

    /* TODO Change handleFieldChange ids to match the correct onces, onces we have them */
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='md'
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                },
            }}
        >
            <DialogTitle>
                {type === "dimension" && t`Dimension Value`}
                {type === "element" && t`Element Value`}
                {type === "extension" && t`Extension Value`}
                {type === "extensionStructure" && t`Extension Structure`}
                {type === "onlyDimension" && t`Add Dimension`}
                {type === "tagsDimensionValues" && t`Dimension Value`}
                {type === "headerDimensionValues" && t`Dimension Value`}
                {type === "labelDimensionValues" && t`Dimension Value`}
                {type === "childDimensionValues" && t`Select element`}
                {type === "contextDimensionValues" && t`Dimension Value`}
                {type === "basicList" && t`Select Element`}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'wrap',
                    gap: 2,
                    padding: 2,
                }}
            >
                {(type === "dimension" || type === "element") &&
                    <LabeledTextField
                        label={
                            type === "dimension" ? t`Dimension` :
                                type === "element" || type === "basicList" ? t`Element` :
                                    t`Extension`
                        }
                        fieldType='dimension'
                        options={mockDimensionValueOptions}
                        value={newDimensionValue.dimensionValue.dimensionName || ''}
                        onValueChange={(selectedDimension) => {
                            const fullDimensionObject = mockDimensionValueOptions.find(
                                option => option.dimensionValue
                            );

                            if (fullDimensionObject) {
                                handleFieldChangeEntireElement('dimensionValue', fullDimensionObject.dimensionValue);
                            }
                        }}
                    />
                }
                {(type === "dimension") && (
                    <>
                        <LabeledTextField
                            label={t`Typed member value`}
                            fieldType='text'
                            value={newDimensionValue.typedMemberValue || ''}
                            onValueChange={(e) => handleFieldChangeEntireElement('typedMemberValue', e.target.value || null)}
                        />
                        <LabeledTextField
                            label={t`Bind to consolidated value`}
                            fieldType='select'
                            options={['Yes', 'No']}
                            value={newDimensionValue.dimensionValue.onlyWhenConsolidated || ''}
                            onValueChange={(e) => handleFieldChangeEntireElement('dimensionValue', { ...newDimensionValue.dimensionValue, onlyWhenConsolidated: e.target.value || null })}
                        />
                    </>
                )}
                {(type === "tagsDimensionValues" || type === "contextDimensionValues") && (
                    <>
                        <LabeledTextField
                            label={t`Dimension Value`}
                            fieldType='dimension'
                            value={newDimensionValue.typedMemberValue || ''}
                            onValueChange={(e) => handleFieldChangeEntireElement('typedMemberValue', e.target.value || null)}
                        />
                        <LabeledTextField
                            label={t`Bind to consolidated value`}
                            fieldType='select'
                            options={['True', 'False']}
                            value={newDimensionValue.dimensionValue.onlyWhenConsolidated || ''}
                            onValueChange={(e) => handleFieldChangeEntireElement('dimensionValue', { ...newDimensionValue.dimensionValue, onlyWhenConsolidated: e.target.value || null })}
                        />
                    </>
                )}
                {(type === "headerDimensionValues" || type === "labelDimensionValues") && (
                    <>
                        <LabeledTextField
                            label={t`Dimension Value`}
                            fieldType='dimension'
                            value={newDimensionValue.typedMemberValue || ''}
                            onValueChange={(e) => handleFieldChangeEntireElement('dimensionValue', e.target.value || null)}
                        />
                        <LabeledTextField
                            label={t`Typed member value`}
                            fieldType='text'
                            value={newDimensionValue.dimensionValue.onlyWhenConsolidated || ''}
                            onValueChange={(e) => handleFieldChangeEntireElement('typedMemberValue', { ...newDimensionValue.dimensionValue, onlyWhenConsolidated: e.target.value || null })}
                        />
                        <LabeledTextField
                            label={t`Bind to consolidated value`}
                            fieldType='select'
                            options={['True', 'False']}
                            value={newDimensionValue.dimensionValue.onlyWhenConsolidated || ''}
                            onValueChange={(e) => handleFieldChangeEntireElement('typedMemberValue', { ...newDimensionValue.dimensionValue, onlyWhenConsolidated: e.target.value || null })}
                        />
                    </>
                )}
                {(type === "basicList" || type === "childDimensionValues") && (
                    <LabeledTextField
                        label={t`Element`}
                        fieldType='dimension'
                        value={newDimensionValue.typedMemberValue || ''}
                        onValueChange={(e) => handleFieldChangeEntireElement('dimensionValue', e.target.value || null)}
                    />
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t`Cancel`}</Button>
                <Button onClick={handleAddDimensionValue}>{t`Add`}</Button>
            </DialogActions>
        </Dialog>
    );
};


const mockDimensionValueOptions: cDimensionValueReference[] = [
    {
        id: '1',
        dimensionValue: {
            taxonomyValue: [
                {
                    dimensionReference: {
                        namespaceAbbreviation: 'NS',
                        name: 'esef2099',
                        description: 'Description of Dimension1',
                    },
                    dimensionValueReference: {
                        namespaceAbbreviation: 'NS',
                        name: 'aVeryCoolName',
                        description: 'Description of Value1',
                    },
                },
                {
                    dimensionReference: {
                        namespaceAbbreviation: 'NS',
                        name: 'esef2012',
                        description: 'Description of Dimension1',
                    },
                    dimensionValueReference: {
                        namespaceAbbreviation: 'NS',
                        name: 'Value1',
                        description: 'Description of Value1',
                    },
                },
            ],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'ifrs-full:comprehensiveincomethatisverylonghahaxdkekw',
            valueName: 'asdsadasdadsadsasadsds',
            name: 'ParsePort',
        },
        typedMemberValue: null,
    },
    {
        id: '2',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension2',
                    description: 'Description of Dimension2',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value2',
                    description: 'Description of Value2',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension2',
            valueName: 'Value2',
            name: 'Dimension2_Value2',
        },
        typedMemberValue: null,
    },
    {
        id: '3',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension3',
                    description: 'Description of Dimension3',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value3',
                    description: 'Description of Value3',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension3',
            valueName: 'Value3',
            name: 'Dimension3_Value3',
        },
        typedMemberValue: null,
    },
    {
        id: '4',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension4',
                    description: 'Description of Dimension4',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value4',
                    description: 'Description of Value4',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension4',
            valueName: 'Value4',
            name: 'Dimension4_Value4',
        },
        typedMemberValue: null,
    },
    {
        id: '5',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension5',
                    description: 'Description of Dimension5',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value5',
                    description: 'Description of Value5',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension5',
            valueName: 'Value5',
            name: 'Dimension5_Value5',
        },
        typedMemberValue: null,
    },
    {
        id: '6',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension6',
                    description: 'Description of Dimension6',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value6',
                    description: 'Description of Value6',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension6',
            valueName: 'Value6',
            name: 'Dimension6_Value6',
        },
        typedMemberValue: null,
    },
    {
        id: '7',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension7',
                    description: 'Description of Dimension7',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value7',
                    description: 'Description of Value7',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension7',
            valueName: 'Value7',
            name: 'Dimension7_Value7',
        },
        typedMemberValue: null,
    },
    {
        id: '8',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension8',
                    description: 'Description of Dimension8',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value8',
                    description: 'Description of Value8',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension8',
            valueName: 'Value8',
            name: 'Dimension8_Value8',
        },
        typedMemberValue: null,
    },
    {
        id: '9',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension9',
                    description: 'Description of Dimension9',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value9',
                    description: 'Description of Value9',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension9',
            valueName: 'Value9',
            name: 'Dimension9_Value9',
        },
        typedMemberValue: null,
    },
    {
        id: '10',
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension10',
                    description: 'Description of Dimension10',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value10',
                    description: 'Description of Value10',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension10',
            valueName: 'Value10',
            name: 'Dimension10_Value10',
        },
        typedMemberValue: null,
    },
];
