import React, { ChangeEvent, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, FormControlLabel, Grid, ListItemText, MenuItem, Popper, TextField, Tooltip, Typography } from '@mui/material';
import { cCompanyGroup, cDateSpectrums, cDimensionValueReference, cTagGroup, cTaxonomyElement } from '../api/types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { t } from '@lingui/macro';

// Define the props interface for the component
interface LabeledTextFieldProps {
  label: string;
  fieldType?: "text" | "select" | "endpoint" | "checkbox" | "element" | "tagGroup" | "dateSpectrum" | "searchSelect" | "dimension" | "alternateCheckbox" | "company";
  selectEndpoint?: string;
  options?: string[] | cDateSpectrums[] | cTagGroup[] | cDimensionValueReference[];
  placeholder?: string;
  value?: string | boolean | undefined;
  disabled?: boolean;
  endpointUrl?: string | undefined;
  onValueChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}
type ElementType = cTaxonomyElement | cDimensionValueReference;

const isTaxonomyElement = (element: ElementType): element is cTaxonomyElement => {
  return (element as cTaxonomyElement).extension !== undefined;
};

const Details = ({ element, fontSize }: { element: ElementType, fontSize: number }) => (
  <>
    <Grid container direction="row" gap={1}>
      {isTaxonomyElement(element) ? (
        <>
          {[
            { label: 'Dimension Value', value: element.extension.name },
            { label: 'Company', value: element.extension?.parentReference?.name },
            { label: 'Children', value: element.extension.childElements.length },
            { label: 'Parent', value: element.extension?.parentReference?.name },
          ].map((item, index) => (
            <Grid item key={index} sx={{ minWidth: 0 }}>
              <Typography fontSize={fontSize} align='left' fontWeight="bold" color="grey" noWrap>
                {item.label}:
              </Typography>
              <Tooltip title={item.value} arrow>
                <Typography fontSize={fontSize} color="black" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 0 }}>
                  {item.value}
                </Typography>
              </Tooltip>
            </Grid>
          ))}
          <Grid item sx={{ minWidth: 0 }}>
            <Typography fontSize={fontSize} fontWeight="bold" color="black" noWrap>
              {t`Taxonomies:`}
            </Typography>
            <Grid container direction="row" spacing={0.5} sx={{ minWidth: 0 }}>
              {element.extension.taxonomies.map((taxonomy, index) => (
                <Grid item key={index} sx={{
                  border: "1px solid #ededed",
                  backgroundColor: "#ededed",
                  borderRadius: 2,
                  fontSize: 12,
                  mt: 0.5,
                  ml: 0.5,
                  p: 0.5,
                  minWidth: 0
                }}>
                  <Tooltip title={taxonomy} arrow>
                    <Typography fontSize={8} noWrap>
                      {taxonomy}
                    </Typography>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sx={{ minWidth: 0 }}>
            <Typography fontSize={fontSize} align='left' fontWeight="bold" color="black" noWrap>
              Dimension Value:
            </Typography>
            <Typography fontSize={fontSize} color="black" fontWeight={400} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 0 }}>
              {element.dimensionValue.dimensionName}
            </Typography>
          </Grid>
          {[
            { label: 'Company', value: element.dimensionValue.name || 'N/A' },
            { label: 'Children', value: element.dimensionValue.taxonomyValue?.length || '0' },
            { label: 'Parent', value: element.dimensionValue.taxonomyValue?.[0]?.dimensionValueReference?.name || 'N/A' }
          ].map((item, index) => (
            <Grid item key={index} sx={{ minWidth: "14vw" }}>
              <Typography fontSize={fontSize} align='left' fontWeight="bold" color="black" noWrap>
                {item.label}:
              </Typography>
              <Typography fontSize={fontSize} color="black" fontWeight={400} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 0 }}>
                {item.value}
              </Typography>
            </Grid>
          ))}
          <Grid item sx={{ minWidth: 0 }}>
            <Typography fontSize={fontSize} fontWeight="bold" color="black" noWrap>
              Taxonomies:
            </Typography>
            <Grid container direction="row" spacing={0.5} sx={{ minWidth: 0 }}>
              {element.dimensionValue.taxonomyValue?.map((taxonomy, index) => (
                <Grid item key={index} sx={{
                  border: "1px solid #ededed",
                  backgroundColor: "#ededed",
                  borderRadius: 2,
                  mt: 0.5,
                  ml: 0.5,
                  p: 0.5,
                  minWidth: 0
                }}>
                  <Tooltip title={taxonomy.dimensionReference?.name || "No Name"} arrow>
                    <Typography fontSize={14} noWrap>
                      {taxonomy.dimensionReference?.name || "No Name"}
                    </Typography>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  </>
);

const LabeledTextField: React.FC<LabeledTextFieldProps> = ({
  label,
  fieldType,
  options = [],
  placeholder,
  value,
  disabled,
  endpointUrl,
  onValueChange,
}) => {
  const [optionsState, setOptionsState] = useState<string[] | cDateSpectrums[] | cTagGroup[] | cTaxonomyElement[] | cDimensionValueReference[]>([]);
  const [selectedElement, setSelectedElement] = useState<cTaxonomyElement | cDimensionValueReference | cCompanyGroup | null>(null);
  const [selectedDateSpectrum, setSelectedDateSpectrum] = useState<cDateSpectrums | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //if (endpointUrl) {
        //const response = await fetch(endpointUrl);
        //const data = await response.json();

        switch (fieldType) {
          case "dimension":
            setOptionsState([
              {
                id: "60150860ae0f631d589ef8fa",
                dimensionValue: {
                  taxonomyValue: [
                    {
                      dimensionReference: {
                        namespaceAbbreviation: "ifrs-full",
                        name: "AdjustmentsForReconcileProfitLoss",
                        description: "AdjustmentsForReconcileProfitLoss"
                      },
                      dimensionValueReference: {
                        namespaceAbbreviation: "ifrs-full",
                        name: "AdjustmentsForReconcileProfitLoss",
                        description: "AdjustmentsForReconcileProfitLoss"
                      }
                    }
                  ],
                  customDimensionValue: null,
                  onlyWhenConsolidated: null,
                  isCustom: true,
                  dimensionName: "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9",
                  valueName: "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9",
                  name: "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9"
                },
                typedMemberValue: null
              },
              {
                id: "615cf6cc8309c47b181c7096",
                dimensionValue: {
                  taxonomyValue: [
                    {
                      dimensionReference: {
                        namespaceAbbreviation: "ifrs-full",
                        name: "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss",
                        description: "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss"
                      },
                      dimensionValueReference: {
                        namespaceAbbreviation: "ifrs-full",
                        name: "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss",
                        description: "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss"
                      }
                    }
                  ],
                  customDimensionValue: null,
                  onlyWhenConsolidated: null,
                  isCustom: true,
                  dimensionName: "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9",
                  valueName: "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9",
                  name: "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9"
                },
                typedMemberValue: null
              },
              {
                id: "6015082aae0f631d589ef8f8",
                dimensionValue: {
                  taxonomyValue: [
                    {
                      dimensionReference: {
                        namespaceAbbreviation: "ifrs-full",
                        name: "AdjustmentsForReconcileProfitLoss",
                        description: "AdjustmentsForReconcileProfitLoss"
                      },
                      dimensionValueReference: {
                        namespaceAbbreviation: "ifrs-full",
                        name: "AdjustmentsForReconcileProfitLoss",
                        description: "AdjustmentsForReconcileProfitLoss"
                      }
                    }
                  ],
                  customDimensionValue: null,
                  onlyWhenConsolidated: null,
                  isCustom: false,
                  dimensionName: "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9",
                  valueName: "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9",
                  name: "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9"
                },
                typedMemberValue: null
              },
              {
                id: "615cf6d78309c47b181c7168",
                dimensionValue: {
                  taxonomyValue: [
                    {
                      dimensionReference: {
                        namespaceAbbreviation: "ifrs-full",
                        name: "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss",
                        description: "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss"
                      },
                      dimensionValueReference: {
                        namespaceAbbreviation: "ifrs-full",
                        name: "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss",
                        description: "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss"
                      }
                    }
                  ],
                  customDimensionValue: null,
                  onlyWhenConsolidated: null,
                  isCustom: false,
                  dimensionName: "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9",
                  valueName: "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9",
                  name: "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9"
                },
                typedMemberValue: null
              }
            ] as cDimensionValueReference[]);
            break
          case "element":
            setOptionsState([
              {
                "isExtension": true,
                "name": "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9",
                "taxonomyElement": null,
                "extension": {
                  "name": "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9",
                  "parentReference": {
                    "namespaceAbbreviation": "ifrs-full",
                    "name": "AdjustmentsForReconcileProfitLoss",
                    "description": "AdjustmentsForReconcileProfitLoss"
                  },
                  "company": null,
                  "taxonomies": [
                    "5e1c9bfe82ac091b20eff353"
                  ],
                  "parserDataTypeId": 6,
                  "isCredit": true,
                  "isInstant": false,
                  "invertSign": true,
                  "isAbstract": false,
                  "labels": [
                    {
                      "lang": "en",
                      "text": "Adjustments for impairment gain and reversal of impairment loss determined in accordance with IFRS 9",
                      "role": "standard",
                      "id": null
                    },
                    {
                      "lang": "it",
                      "text": "Rettifiche per riduzione di valore e ripristino di valore determinate in conformità all'IFRS 9",
                      "role": "standard",
                      "id": null
                    }
                  ],
                  "documentationLabels": null,
                  "childElements": [],
                  "childElementReferences": [],
                  "canDelete": false,
                  "description": "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9 (AdjustmentsForReconcileProfitLoss)",
                  "isSafe": true,
                  "id": "60150860ae0f631d589ef8fa"
                }
              },
              {
                "isExtension": true,
                "name": "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9",
                "taxonomyElement": null,
                "extension": {
                  "name": "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9",
                  "parentReference": {
                    "namespaceAbbreviation": "ifrs-full",
                    "name": "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss",
                    "description": "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss"
                  },
                  "company": null,
                  "taxonomies": [
                    "615cb14e8309c47b18199cb2",
                    "62ed9f844b8c93c718054de6",
                    "643e3640ae0f62122c8d578c"
                  ],
                  "parserDataTypeId": 6,
                  "isCredit": true,
                  "isInstant": false,
                  "invertSign": true,
                  "isAbstract": false,
                  "labels": [
                    {
                      "lang": "en",
                      "text": "Adjustments for impairment gain and reversal of impairment loss determined in accordance with IFRS 9",
                      "role": "standard",
                      "id": "615cf6cc8309c47b181c7094"
                    },
                    {
                      "lang": "it",
                      "text": "Rettifiche per riduzione di valore e ripristino di valore determinate in conformità all'IFRS 9",
                      "role": "standard",
                      "id": "615cf6cc8309c47b181c7095"
                    },
                    {
                      "lang": "el",
                      "text": "Προσαρμογές για κέρδος απομείωσης και αναστροφή της ζημίας απομείωσης που προσδιορίζονται σύμφωνα με το ΔΠΧΑ 9",
                      "role": "standard",
                      "id": null
                    },
                    {
                      "lang": "fr",
                      "text": "Ajustements pour le gain de dépréciation et la reprise de la perte de valeur déterminés conformément à l'IFRS 9",
                      "role": "standard",
                      "id": null
                    }
                  ],
                  "documentationLabels": null,
                  "childElements": [],
                  "childElementReferences": [],
                  "canDelete": false,
                  "description": "AdjustmentsForImpairmentGainAndReversalOfImpairmentLossDeterminedInAccordanceWithIfrs9 (AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss)",
                  "isSafe": true,
                  "id": "615cf6cc8309c47b181c7096"
                }
              },
              {
                "isExtension": true,
                "name": "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9",
                "taxonomyElement": null,
                "extension": {
                  "name": "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9",
                  "parentReference": {
                    "namespaceAbbreviation": "ifrs-full",
                    "name": "AdjustmentsForReconcileProfitLoss",
                    "description": "AdjustmentsForReconcileProfitLoss"
                  },
                  "company": null,
                  "taxonomies": [
                    "5e1c9bfe82ac091b20eff353"
                  ],
                  "parserDataTypeId": 6,
                  "isCredit": false,
                  "isInstant": false,
                  "invertSign": false,
                  "isAbstract": false,
                  "labels": [
                    {
                      "lang": "en",
                      "text": "Adjustments for impairment loss determined in accordance with IFRS 9",
                      "role": "standard",
                      "id": null
                    },
                    {
                      "lang": "it",
                      "text": "Rettifiche per perdita di valore determinate secondo IFRS 9",
                      "role": "standard",
                      "id": null
                    }
                  ],
                  "documentationLabels": null,
                  "childElements": [],
                  "childElementReferences": [],
                  "canDelete": false,
                  "description": "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9 (AdjustmentsForReconcileProfitLoss)",
                  "isSafe": true,
                  "id": "6015082aae0f631d589ef8f8"
                }
              },
              {
                "isExtension": true,
                "name": "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9",
                "taxonomyElement": null,
                "extension": {
                  "name": "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9",
                  "parentReference": {
                    "namespaceAbbreviation": "ifrs-full",
                    "name": "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss",
                    "description": "AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss"
                  },
                  "company": null,
                  "taxonomies": [
                    "615cb14e8309c47b18199cb2",
                    "62ed9f844b8c93c718054de6",
                    "643e3640ae0f62122c8d578c"
                  ],
                  "parserDataTypeId": 6,
                  "isCredit": false,
                  "isInstant": false,
                  "invertSign": false,
                  "isAbstract": false,
                  "labels": [
                    {
                      "lang": "en",
                      "text": "Adjustments for impairment loss determined in accordance with IFRS 9",
                      "role": "standard",
                      "id": "615cf6d78309c47b181c7166"
                    },
                    {
                      "lang": "it",
                      "text": "Rettifiche per perdita di valore determinate secondo IFRS 9",
                      "role": "standard",
                      "id": "615cf6d78309c47b181c7167"
                    },
                    {
                      "lang": "el",
                      "text": "Προσαρμογές για ζημία απομείωσης που προσδιορίζονται σύμφωνα με το ΔΠΧΑ 9",
                      "role": "standard",
                      "id": null
                    }
                  ],
                  "documentationLabels": null,
                  "childElements": [],
                  "childElementReferences": [],
                  "canDelete": false,
                  "description": "AdjustmentsForImpairmentLossDeterminedInAccordanceWithIfrs9 (AdjustmentsForImpairmentLossReversalOfImpairmentLossRecognisedInProfitOrLoss)",
                  "isSafe": true,
                  "id": "615cf6d78309c47b181c7168"
                }
              }
            ] as cTaxonomyElement[]);
            break;
          case "tagGroup":
            setOptionsState([
              {
                "name": "Assets",
                "id": "5e692ba94d626d13202ccb64"
              },
              {
                "name": "BalanceSheet",
                "id": "66a181c17a6bd5a793d894ac"
              },
              {
                "name": "CashFlowsStatement",
                "id": "5ae01cac82ac090a3cb8465f"
              },
              {
                "name": "ComprehensiveIncomeBeforeTax",
                "id": "5c9c6a3a072d762160ad532e"
              },
              {
                "name": "ComprehensiveIncomeNetOfTax",
                "id": "5e5622c64d626d19007d3a90"
              }
            ] as cTagGroup[]);
            break;
          case "dateSpectrum":
            setOptionsState([
              {
                "dateSpectrum": "end",
                "preferredLabelRole": "http://www.xbrl.org/2003/role/periodEndLabel",
                "labelRoles": [
                  "http://www.xbrl.org/2003/role/periodEndLabel"
                ],
                "id": "5f92526604b97dfb3009c1ba"
              },
              {
                "dateSpectrum": "previous",
                "preferredLabelRole": null,
                "labelRoles": [],
                "id": "53e7d11638d41a27e45008d8"
              },
              {
                "dateSpectrum": "standard",
                "preferredLabelRole": "http://www.xbrl.org/2003/role/label",
                "labelRoles": [
                  "http://www.xbrl.org/2003/role/label"
                ],
                "id": "5f9257cf04b97dfb3009c5c3"
              },
              {
                "dateSpectrum": "start",
                "preferredLabelRole": "http://www.xbrl.org/2003/role/periodStartLabel",
                "labelRoles": [
                  "http://www.xbrl.org/2003/role/periodStartLabel"
                ],
                "id": "53e7d11638d41a27e45008d7"
              },
              {
                "dateSpectrum": "total",
                "preferredLabelRole": "http://www.xbrl.org/2003/role/totalLabel",
                "labelRoles": [
                  "http://www.xbrl.org/2003/role/totalLabel"
                ],
                "id": "5f9251db04b97dfb3009c18c"
              }
            ] as cDateSpectrums[]);
            break;
          case "endpoint":
            setOptionsState([] as string[]);
            break;
          case "company":
            setOptionsState([{
              "name": "Toldbodens Revision & Regnskab, Reg. Revisionsanpartsselskab",
              "data": "12838840",
              "id": "53e76dc038d41a41407a4c0a"
            },
            {
              "name": "Revisionsfirmaet Ivan Kjeldsen Statsautoriseret Revisionsanpartsselskab",
              "data": "30613791",
              "id": "53e76dc038d41a41407a4c68"
            },
            {
              "name": "Dummy Company",
              "data": "DK32670075",
              "id": "53e76dc138d41a41407a4c78"
            },
            {
              "name": "Mogens Kibsgaard. Statsautoriseret Revisionsanpartsselskab",
              "data": "67425618",
              "id": "58870789a772e41a0c4369c0"
            },
            {
              "name": "Oerlemans Packaging B.V.",
              "data": "16083949",
              "id": "5a1eac25a772e42134a74d59"
            }] as cCompanyGroup[]);
            break;
          default:
            break;
        }
        // }
      } catch (error) {
        console.error("Failed to fetch data from endpoint:", error);
      }
    };

    if (["endpoint", "element", "tagGroup", "dateSpectrum", "company"].includes(fieldType || "")) {
      fetchData();
    } else {
      if (fieldType !== "element") {
        setOptionsState(options);
      }
    }
  }, [fieldType, endpointUrl, options]);

  const renderFieldType = () => {

    switch (fieldType) {
      case "dimension":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                forcePopupIcon={false}
                options={optionsState as cDimensionValueReference[]}
                getOptionLabel={(option) => (option as cDimensionValueReference).dimensionValue?.dimensionName || ""}
                renderOption={(props, option) => (
                  <li {...props} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    border: "1px solid #e6e6e6",
                    padding: 4,
                    fontSize: 14,
                    justifyContent: "flex-start",
                  }}>
                    <Details element={option} fontSize={14} />
                  </li>
                )}
                value={selectedElement as cDimensionValueReference}
                onChange={(event, newValue) => {
                  setSelectedElement(newValue || null);
                  console.log("Selected Element:", newValue);
                  if (onValueChange) {
                    onValueChange(event as ChangeEvent<HTMLInputElement>);
                  }
                }}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    sx={{ width: "100%" }}
                    inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
                PopperComponent={({ children, ...popperProps }) => (
                  <Popper {...popperProps}>
                    {children}
                  </Popper>
                )}
              />
              {selectedElement && <Details element={selectedElement as cDimensionValueReference} fontSize={12} />}
            </Grid>
          </Grid>
        );

      case "element":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                forcePopupIcon={false}
                options={optionsState as cTaxonomyElement[]}
                getOptionLabel={(option) => option.extension.name || ""}
                renderOption={(props, option) => (
                  <li {...props} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    border: "1px solid #e6e6e6",
                    padding: 4,
                    fontSize: 6,
                    justifyContent: "flex-start",
                  }}>
                    <Details element={option} fontSize={14} />
                  </li>
                )}
                value={selectedElement as cTaxonomyElement}
                onChange={(event, newValue) => {
                  setSelectedElement(newValue || null);
                  console.log("Selected Element:", newValue);
                  if (onValueChange) {
                    onValueChange(event as ChangeEvent<HTMLInputElement>);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    sx={{ width: "100%" }}
                    inputProps={{ ...params.inputProps, style: { fontSize: 14 }, endAdornment: null }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
                PopperComponent={({ children, ...popperProps }) => (
                  <Popper {...popperProps}>
                    {children}
                  </Popper>
                )}
              />
              {selectedElement && <Details element={selectedElement as cTaxonomyElement} fontSize={14} />}
            </Grid>
          </Grid>
        );

      case "searchSelect":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={options as string[]}
                getOptionLabel={(option) => option}
                value={value?.toString() || ""}
                onChange={(event, newValue) => {
                  if (onValueChange) {
                    const newEvent = {
                      ...event,
                      target: {
                        ...event.target,
                        value: newValue || "",
                      },
                    } as ChangeEvent<HTMLInputElement>;
                    onValueChange(newEvent);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    sx={{ width: "100%" }}
                    inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        );

      case "select":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                autoComplete='off'
                sx={{ width: "100%" }}
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                variant="standard"
                placeholder={placeholder || ""}
                value={value || ""}
                disabled={disabled}
                onChange={(event) => onValueChange && onValueChange(event as ChangeEvent<HTMLInputElement>)}
              >
                {(optionsState as string[]).map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );

      case "tagGroup":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={optionsState as cTagGroup[]}
                getOptionLabel={(option) => option.name}
                value={
                  (optionsState as cTagGroup[]).find((option) => option.id === value) || null
                }
                onChange={(event, newValue) => {
                  if (onValueChange) {
                    const newEvent = {
                      ...event,
                      target: {
                        ...event.target,
                        value: newValue ? newValue.id : "",
                      },
                    } as ChangeEvent<HTMLInputElement>;
                    onValueChange(newEvent);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        );

      case "dateSpectrum":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={(optionsState as cDateSpectrums[]).map((option) => option.dateSpectrum)}
                getOptionLabel={(option) => option}
                value={selectedDateSpectrum ? selectedDateSpectrum.dateSpectrum : ""}
                onChange={(event, newValue) => {
                  const selectedOption = (optionsState as cDateSpectrums[]).find(
                    (option) => option.dateSpectrum === newValue
                  );
                  setSelectedDateSpectrum(selectedOption || null);
                  if (onValueChange) {
                    const newEvent = {
                      ...event,
                      target: {
                        ...event.target,
                        value: newValue || "",
                      },
                    } as ChangeEvent<HTMLInputElement>;
                    onValueChange(newEvent);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    sx={{ width: "100%" }}
                    inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        );

      case "checkbox":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  p: 1,
                  height: 10,
                  ':focus': {
                    backgroundColor: "orange",
                  },
                }}
                control={
                  <Checkbox
                    checked={Boolean(value)}
                    onChange={(event) => {
                      if (onValueChange) {
                        onValueChange(event as ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />
                } label={undefined}
              />
            </Grid>
          </Grid>
        );

      case "alternateCheckbox":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  p: 1,
                  height: 10,
                }}
                control={
                  <Checkbox
                    checked={value === true}
                    indeterminate={value === null}
                    icon={
                      <CheckBoxOutlineBlankIcon />
                    }
                    indeterminateIcon={
                      <IndeterminateCheckBoxIcon />
                    }
                    checkedIcon={<CheckBoxIcon />}
                    onChange={(event) => {
                      let newValue: boolean | null = null;
                      if (value === null) {
                        newValue = true;
                      } else if (value === true) {
                        newValue = false;
                      } else if (value === false) {
                        newValue = null;
                      }
                      if (onValueChange) {
                        const newEvent = {
                          ...event,
                          target: {
                            ...event.target,
                            value: newValue,
                          },
                        } as unknown as ChangeEvent<HTMLInputElement>;

                        onValueChange(newEvent);
                      }
                    }}
                  />
                }
                label={undefined}
              />
            </Grid>
          </Grid >
        );

      case "company":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={optionsState as cCompanyGroup[]}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                value={(optionsState as cCompanyGroup[]).find((option) => option.name === value) || null}
                onChange={(event, newValue) => {
                  setSelectedElement(newValue);
                  if (onValueChange) {
                    onValueChange({ target: { value: newValue?.name } } as ChangeEvent<HTMLInputElement>);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    sx={{ width: "100%" }}
                    inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        );

      case "text":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete='off'
                sx={{ width: "100%" }}
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                variant="standard"
                placeholder={placeholder || ""}
                value={value || ""}
                disabled={disabled}
                onChange={(event) => onValueChange && onValueChange(event as ChangeEvent<HTMLInputElement>)}
              />
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  return renderFieldType();
};

export default LabeledTextField;