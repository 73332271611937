import {
  TableHead,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  TableCellProps,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export interface TableColumnHeader<T> {
  id: keyof T | string;
  label: string;
  width?: number;
  optionsHeaderText?: string;
  options?: string[];
  shouldBeFiltered?: boolean;
  inputType?: "text" | "select";
}

export interface TableHeaderProps<T> {
  columnHeaders: TableColumnHeader<T>[];
  expandable?: boolean;
  nested?: boolean;
  setFilter?: (id: string, value: string) => void;
}

interface StyledTableCellProps extends TableCellProps {
  width?: number;
  nested?: boolean;
}

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const StyledTableCell = styled((props: StyledTableCellProps) => {
  const { nested, width, ...nativeProps } = props;
  return (
    <TableCell sx={{ width: width ? width : undefined }} {...nativeProps}>
      {nativeProps.children}
    </TableCell>
  );
})(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#072a44",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function TableHeader<T>({
  columnHeaders,
  expandable,
  nested,
  setFilter,
}: TableHeaderProps<T>) {

  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});

  const handleSelectChange = (id: string, value: string) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    setFilter && setFilter(id, value);
  };

  const TableHeaderComponent = ({ optionsHeaderText }: { optionsHeaderText: string }) => {
    return (
      <Typography fontSize={"0.75rem"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {capitalizeFirstLetter(optionsHeaderText || "")}
        <FilterAltIcon fontSize="small" sx={{ marginLeft: 0.5, color: "black" }} />
      </Typography>
    );
  };

  const commonInputStyles = {
    height: '40px',
    '& .MuiOutlinedInput-root': {
      height: '100%',
      padding: '0 14px',
      borderRadius: '4px',
    },
    '& .MuiSelect-select': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <TableHead>
      <TableRow>
        {setFilter && (
          <>
            {expandable && <StyledTableCell nested={nested}></StyledTableCell>}
            {columnHeaders.map((ch, index) => (
              <StyledTableCell
                key={`head_column_${index}`}
                width={ch.width ? ch.width : undefined}
                nested={nested}
                sx={{
                  backgroundColor: '#072a44',
                  color: 'white',
                  '&:hover': {
                    borderColor: '#FFFFFF',
                  },
                  '&:focus': {
                    borderColor: '#FFFFFF',
                  },
                }}
              >
                {ch.shouldBeFiltered ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {ch.inputType === "select" ? (
                      <FormControl
                        sx={{ width: ch.width || '100%', minWidth: 150, ...commonInputStyles }}
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel
                          sx={{ color: "black", fontSize: '0.75rem' }}
                        >
                          {capitalizeFirstLetter(ch.optionsHeaderText || "")}
                        </InputLabel>
                        <Select
                          sx={{
                            color: "black",
                            fontSize: '0.75rem',
                            backgroundColor: 'white',
                            '& .MuiSelect-select': {
                              fontSize: '0.75rem',
                            },
                            '& .MuiSelect-icon': {
                              color: 'black',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'black',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'black',
                            },
                          }}
                          label={capitalizeFirstLetter(ch.optionsHeaderText || "")}
                          value={selectedValues[ch.id as string] || ''}
                          onChange={(e) => handleSelectChange(ch.id as string, e.target.value as string)}
                        >
                          {ch.options?.map((option, index) => (
                            <MenuItem
                              sx={{
                                color: "black",
                                fontSize: '0.75rem',
                              }}
                              key={option + index}
                              value={option}
                            >
                              {capitalizeFirstLetter(option)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        sx={{
                          color: "black",
                          backgroundColor: "white",
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                          '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
                          transition: 'border-color 0.2s ease',
                          borderRadius: '4px',
                          ...commonInputStyles,
                        }}
                        label={<TableHeaderComponent optionsHeaderText={ch.optionsHeaderText || ""} />}
                        variant="outlined"
                        size="small"
                        value={selectedValues[ch.id as string] || ''}
                        onChange={(e) => handleSelectChange(ch.id as string, e.target.value)}
                        InputLabelProps={{ sx: { color: "black" } }}
                      />
                    )}
                  </Box>
                ) : (
                  <Typography
                    fontSize={"0.75rem"}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    {capitalizeFirstLetter(ch.label)}
                  </Typography>
                )}
              </StyledTableCell>
            ))}
          </>
        )}
        {!setFilter && (
          <>
            {expandable && <StyledTableCell nested={nested}></StyledTableCell>}
            {columnHeaders.map((ch, index) => (
              <StyledTableCell
                key={`head_column_${index}`}
                width={ch.width}
                sx={{
                  backgroundColor: "#072a44",
                  color: "white",
                  fontSize: "0.75rem",
                }}
              >
                {capitalizeFirstLetter(ch.label)}
              </StyledTableCell>
            ))}
          </>
        )}
      </TableRow>
    </TableHead>
  );
}
