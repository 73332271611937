import {
  Grid,
  Typography,
  Button,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import { ObjectsWrapper } from "./objectsWrapper";
import {
  cLabelsResult,
  ISelectTemplate,
  navBarProps,
} from "./../../api/types";
import DataTable from "./../dataTable";
import { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import CustomObjectsDialog from "./customObjectsDialog";
import {
  Tagscolumns,
  HeadersColumns,
  labelsColumns,
  customDimensionColumns,
  dimensionValuesColumns,
  extensionElementsColumns,
  settingsColumns,
  contextSettingsColumns,
  globalRequestColumns,
  hardcodedLabelsData,
} from "./customObjectsPreData";
import { Loader } from "../loader";
import { usePostQuery } from "../../hooks/usePostQuery";
import { getTaxonomiesFromLocalStorage } from "../../helpers/taxonomyHelpers";
import AlertSnackbar from "../alertSnackbar";

interface BaseReq {
  id: string;
  [key: string]: any;
}

interface CutsomTableProps {
  headerTitle: string;
  url: string;
  selectTemplate: ISelectTemplate;
  onUpdate: (
    updateStatus: "error" | "warning" | "info" | "success",
    reason?: string | undefined
  ) => void;
  isConsistencyCheck?: boolean;
}

const getQuerySelector = (selectTemplate: string) => {
  switch (selectTemplate) {
    case "labels":
      return {
        filters: {
          label: null,
          element: null,
          company: null,
          tagGroug: null,
          dateSpectrum: null,
          language: null,
        },
        taxonomyIds: getTaxonomiesFromLocalStorage().map((taxonomy: { id: string }) => taxonomy.id),
      }
    case "tags":
      return {
        filters: {
          tag: null,
          tagGroup: null,
          dateSpectrum: null,
        },
        taxonomyIds: getTaxonomiesFromLocalStorage().map((taxonomy: { id: string }) => taxonomy.id),
      }
    case "header":
      return {
        filters: {
          header: null,
          dateSpectrum: null,
        }
      }
    default:
      return { filters: {} };
  }
}

export const EmptyConversionQuery: any = (selectTemplate: string) => {
  return getQuerySelector(selectTemplate);
};

export const CustomObjectsPageComponent = <T extends BaseReq>({
  headerTitle,
  url,
  selectTemplate,
  onUpdate,
  isConsistencyCheck = false,
}: CutsomTableProps) => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [columns, setColumns] = useState<navBarProps[]>([]);
  const [req, setReq] = useState(() => EmptyConversionQuery(selectTemplate));
  const [alertMessage, setAlertMessage] = useState<
    | {
      message: string;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);

  useEffect(() => {
    if (req) {
      const taxonomyIds = getTaxonomiesFromLocalStorage().map((taxonomy: { id: string }) => taxonomy.id);
      setReq((prevReq: any) => ({
        ...prevReq,
        filters: {
          ...prevReq.filters,
          taxonomyIds,
        },
        page: 1,
        pageCount: 20,
      }));
    }
  }, [cacheBuster]);

  const { error, loading, } = usePostQuery<T[]>(
    `api/custom-objects/${url}/search?cb=${cacheBuster}`,
    req,
  )

  const data = hardcodedLabelsData;

  useEffect(() => {
    if (error) {
      const errorData = error.response?.data as { IsTranslated?: boolean; Exceptions?: string[] };
      if (errorData?.IsTranslated && errorData?.Exceptions) {
        setAlertMessage({ message: errorData.Exceptions[0], severity: "error" });
      } else {
        setAlertMessage({ message: t`An unexpected error occurred`, severity: "error" });
      }
    }
  }, [error]);

  /* if (data) {
    console.log(data, "Data fetched");
    data.filters?.forEach((filter: any) => {
      filter.options = filter.options.map((option: any) => ({
        value: option,
        label: option,
      }));
    });
  } */

  const handleSetFilter = (columnId: string, value: string) => {
    setReq((prevReq: any) => ({
      ...prevReq,
      filters: {
        ...prevReq.filters,
        [columnId]: value, // Update the corresponding filter in the filters object
      }
    }));
  };

  useEffect(() => {
    console.log(req, "Updated request object");
  }, [req]);


  useEffect(() => {
    const columnsMapping = {
      tags: Tagscolumns,
      header: HeadersColumns,
      labels: labelsColumns,
      customDimensions: customDimensionColumns,
      dimensionValue: dimensionValuesColumns,
      extensionElements: extensionElementsColumns,
      settings: settingsColumns,
      contextSettings: contextSettingsColumns,
      globalRequests: globalRequestColumns,
      consistencyCheck: [],
    };

    setColumns(columnsMapping[selectTemplate] || []);
  }, [selectTemplate]);

  const handlecloseDialog = (
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) => {
    setOpenDialog(false);
    setSelectedId(undefined);
    if (updateStatus === "success" || updateStatus === "error") {
      setAlertMessage({
        message: reason || t`Something went wrong`,
        severity: updateStatus,
      });
    }
  };

  return (
    <ObjectsWrapper>
      <Grid container>
        <Grid item xs={12} sm={10}>
          <Typography variant="h5" fontWeight={"bold"} sx={{ pl: 2, pb: 2 }}>
            {headerTitle}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box sx={{ marginLeft: "auto" }}>
              <Typography
                sx={{
                  display: "inline-block",
                  pb: 0.5,
                  pt: 0.5,
                  pl: 1,
                  pr: 4,
                  borderRadius: 2,
                }}
              >
                {t`Total:`}
                <span style={{ paddingLeft: 5 }}>{data ? data.length : 0}</span>
              </Typography>
              {!isConsistencyCheck && selectTemplate !== "globalRequests" && (
                <Button variant="contained"
                  onClick={() => {
                    setOpenDialog(true)
                  }}
                >{t`Add`}</Button>
              )}
              {isConsistencyCheck && (
                <Button variant="contained" onClick={() => { }}>
                  {t`Check`}
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {loading && !isConsistencyCheck && <Loader height={750} />}
      {loading && isConsistencyCheck && (
        <Container sx={{ height: 750, display: 'flex', justifyContent: 'center', alignItems: 'center', color: "grey" }}>
          {selectTemplate === "consistencyCheck" && (
            <Typography sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>
              {t`Please select the appropriate taxonomies and click "Check" to view the results.`}
            </Typography>
          )}
        </Container>
      )}
      {!loading && (
        <DataTable<T>
          columns={columns}
          data={data as unknown as T[]}
          onClickRow={setSelectedId}
          noDataMessage={t`No data found`}
          setFilter={handleSetFilter}
        />
      )}
      <CustomObjectsDialog
        id={selectedId}
        url={url}
        open={selectedId !== undefined || openDialog}
        onClose={handlecloseDialog}
        selectTemplate={selectTemplate}
      />
      <AlertSnackbar
        alertMessage={alertMessage}
        updateAlertStatus={setAlertMessage}
      />
    </ObjectsWrapper>
  );
};
