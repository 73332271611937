import axios from "axios";
import { cTagGroup, UsageLogPayload, UsageLogResponse } from "./types";

export const getTagGroups = async (): Promise<cTagGroup[]> => {
    return axios.get(`/api/customObjects/tagGroups`);
}

export const getAvaliable = async (): Promise<string[]> => {
    return axios.get(`/api/customObjects/avaliable`);
}

export const getUsageLogs = async (id: string, payload: UsageLogPayload): Promise<UsageLogResponse> => {
    const response = await axios.post(`/api/customObjects/usageLogs`, payload);
    return response.data;
}

export const fetchApiData = async (selectTemplate: string, taxonomyIds: string[]) => {
    try {
        switch (selectTemplate) {
            case "tags":
                const [dateSpectrum, taxonomies, contexts, tagGroups] = await Promise.all([
                    axios.get('api/parser/dateSpectrums'),
                    axios.get('api/taxonomies/languages', {
                        params: {
                            taxonomyIds: taxonomyIds,
                        }
                    }),
                    axios.get('api/contexts/available'),
                    axios.get('api/parser/tagGroups'),
                ]);

                return {
                    dateSpectrum: dateSpectrum.data,
                    taxonomies: taxonomies.data,
                    contexts: contexts.data,
                    tagGroups: tagGroups.data,
                };

            case "header":
                const [parserDatatypes, anotherTagGroup] = await Promise.all([
                    axios.get('api/parserdatatypes'),
                    axios.get('api/parser/tagGroups'),
                ]);

                return {
                    parserDatatypes: parserDatatypes.data,
                    anotherTagGroup: anotherTagGroup.data,
                };

            // Add more cases for other templates
            default:
                throw new Error(`Unknown template: ${selectTemplate}`);
        }
    } catch (error) {
        console.error('Error fetching API data:', error);
        throw error;
    }
};