import React, { useState, useEffect, ChangeEvent, useRef, useMemo, useReducer } from 'react';
import {
    Dialog,
    AppBar,
    Tabs,
    Tab,
    DialogContent,
    Button,
    Box,
    Grid,
    Container,
    DialogActions,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import LabeledTextField from '../labeledTextField';
import { Loader } from '../loader';
import {
    tagsTemplate,
    headerTemplate,
    customDimensionsTemplate,
    dimensionValueTemplate,
    extensionElementsTemplate,
    settingsTemplate,
    ContextSettingsTemplate,
    labelsTemplate,
    HeadersColumns,
    Tagscolumns,
    labelsColumns,
    dimensionValuesColumns,
    ContextColumns,
    TagsAltColumns
} from './customObjectsPreData';
import { t } from '@lingui/macro';
import { cContextSettingsColumns, cCustomDimensionValueDetails, cDimensionValueReference, cHeaderDetails, cLabelDetails, cTagDetails, dataMatchingToNavBar, selectTableProps } from '../../api/types';
import SelectTaxonomies from './selectTaxonomies';
import { getAllTaxonomies, getTaxonomiesFromLocalStorage } from '../../helpers/taxonomyHelpers';
import GenericGridTable from './genericGridTable';
import axios from 'axios';
import LabelTable from '../labelTable';
import UsageComponent from './usageComponent';
import DataTable from '../dataTable';
import SelectTable from './selectTable';
import { fetchApiData } from '../../api/customObjects';

interface AddDialogProps {
    open: boolean;
    onClose: (updateStatus: "none" | "success" | "error", reason?: string) => void;
    selectTemplate: string;
    id: string | undefined;
    url: string;
}

const CustomObjectsDialog = ({ open, onClose, selectTemplate, id, url }: AddDialogProps) => {
    const [data, setData] = useState<dataMatchingToNavBar | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("details");
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const hasRun = useRef(false);
    const openDataDialog = useRef(false);

    const handleTaxonomySetter = (selectedTaxonomies: any) => {
        setData((prevData: dataMatchingToNavBar | null) => {
            if (!prevData) return prevData;
            return {
                ...prevData,
                taxonomies: selectedTaxonomies,
            };
        });
    };


    const templateDataSelected = useMemo(() => {
        switch (selectTemplate) {
            case "tags":
                return tagsTemplate();
            case "header":
                return headerTemplate();
            case "labels":
                return labelsTemplate();
            case "customDimensions":
                return customDimensionsTemplate();
            case "dimensionValue":
                return dimensionValueTemplate();
            case "extensionElements":
                return extensionElementsTemplate();
            case "settings":
                return settingsTemplate();
            case "contextSettings":
                return ContextSettingsTemplate();
            default:
                return tagsTemplate();
        }
    }, [selectTemplate]);

    const getApiData = async () => {
        try {
            setIsLoading(true);
            const taxonomyIds = getTaxonomiesFromLocalStorage().map((taxonomy: { id: string }) => taxonomy.id);
            const apiData = await fetchApiData(selectTemplate, taxonomyIds || []);

            console.log(apiData);
            console.log(data);

            // TODO: match the data from the API with the template
        } catch (error) {
            console.error('Error in getApiData:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        console.log("useEffect");
        if (!hasRun.current) {
            console.log("useEffect if");
            setActiveTab("details");
            getApiData();
            //TODO when the API is ready, fetch the data from the API and match it with the template
            if (url) {
                axios.get(url).then((response) => {
                    setData(response.data);
                }).catch((error) => {
                    console.error('Error fetching data', error);
                }).finally(() => {
                    setIsLoading(false);
                }
                );
            }


            setData(templateDataSelected);
            const selectedTaxonomies = getAllTaxonomies().filter(tax => tax.isSelected);
            setData((prevData: dataMatchingToNavBar | null) => {
                if (!prevData || !prevData.taxonomies) return prevData;
                return {
                    ...prevData,
                    taxonomies: selectedTaxonomies.map(tax => tax.id),
                };
            });
            hasRun.current = true;
            setIsLoading(false);
        }
    }, []);

    const handleTabChange = (event: ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
    };

    const pagesShouldNotBeMapped = useMemo(() => [
        "usage",
        "dimensionValues",
        "taxonomies",
        "extension",
        "childDimensionValues",
        "childElements",
        "documentation",
        "labels",
        "additionalElements",
        "extensionStructure",
        "customLabels",
        "customHeaders",
        "customTags",
        "customDimensionValues",
        "dimensionLabels",
        "contexts",
        "valueLabels",
    ], []);

    //TODO when the API is ready, clean the data before sending it to the API
    const cleanData = (data: any): any => {
        const cleanField = (field: any): any => {
            if (field && typeof field === 'object' && 'fieldType' in field && 'value' in field) {
                return field.value;
            }
            if (field && typeof field === 'object' && !Array.isArray(field)) {
                return Object.fromEntries(Object.entries(field).map(([key, value]) => [key, cleanField(value)]));
            }
            return field;
        };
        return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, cleanField(value)]));
    };

    const handleApply = () => {
        const cleanedData = cleanData(data);
        console.log(cleanedData);
        onClose("success");
        hasRun.current = false;
        openDataDialog.current = true;
        setActiveTab("details");
    };

    const handleClose = () => {
        onClose("none");
        hasRun.current = false;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
        const { type, checked, value } = event.target;
        const inputValue = type === "checkbox" ? checked : value;
        setData((prevData: dataMatchingToNavBar | null) => {
            if (!prevData || !prevData[activeTab]) return prevData;
            const updatedData = {
                ...prevData,
                [activeTab]: {
                    ...prevData[activeTab],
                    [key]: {
                        ...prevData[activeTab][key],
                        value: inputValue,
                    },
                },
            };
            return updatedData;
        });
    };

    const handleDelete = async () => {
        try {
            await axios.delete('/api/test/delete'); //TODO Adjust the endpoint as needed
            console.log('Deleted successfully');
            setIsDeleteDialogOpen(false);
            onClose("success", "deleted");
        } catch (error) {
            console.error('Delete failed', error);
            onClose("error", "delete failed");
        }
    };

    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const tabKeys = Object.keys(data || {}).filter(key => key !== 'id');

    return (
        <>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>{t`Delete Confirmation`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t`Are you sure you want to delete this item?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        {t`Cancel`}
                    </Button>
                    <Button onClick={handleDelete} color="secondary" variant="contained">
                        {t`Delete`}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Main dialog */}
            <Dialog
                open={open}
                maxWidth="xl"
                fullWidth
                onClose={handleClose}
            >
                <AppBar position="static">
                    {isLoading ? (
                        <Loader height={70} />
                    ) : (
                        <Tabs value={activeTab} onChange={handleTabChange}>
                            {tabKeys.map(key => (
                                <Tab
                                    sx={{ color: "white" }}
                                    key={key}
                                    value={key}
                                    label={data?.[key]?.navBarName || key}
                                />
                            ))}
                        </Tabs>
                    )}
                </AppBar>
                <DialogContent sx={{ width: "auto", maxHeight: "85vh", minHeight: "60vh" }}>
                    {pagesShouldNotBeMapped.includes(activeTab) && (
                        <>
                            {activeTab === "usage" && (<>
                                <UsageComponent id={id} />
                            </>)}
                            {activeTab === "customLabels" && (
                                <LabelTable
                                    initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                    onLabelsChange={(labels: any) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: labels,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                    fieldType='documentation'
                                />
                            )}
                            {activeTab === "valueLabels" && (
                                <LabelTable
                                    initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                    onLabelsChange={(labels: any) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: labels,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                    fieldType='documentation'
                                />
                            )}
                            {activeTab === "customHeaders" && (
                                <DataTable<cHeaderDetails>
                                    columns={HeadersColumns()}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                            {activeTab === "customTags" && (
                                <DataTable<cTagDetails>
                                    columns={TagsAltColumns()}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                            {activeTab === "contexts" && (
                                <DataTable<cContextSettingsColumns>
                                    columns={ContextColumns()}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                            {activeTab === "customDimensionValues" && (
                                <DataTable<cCustomDimensionValueDetails>
                                    columns={dimensionValuesColumns()}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                            {(activeTab === "dimensionLabels") && (
                                data?.id === "contextSettings" ? (
                                    <GenericGridTable
                                        type="contextDimensionValues"
                                        data={data?.[activeTab]?.dataTable?.data || []}
                                        setData={(newData: cDimensionValueReference[]) => {
                                            setData((prevData: dataMatchingToNavBar | null) => {
                                                if (!prevData || !prevData[activeTab]) return prevData;

                                                const updatedData = {
                                                    ...prevData,
                                                    [activeTab]: {
                                                        ...prevData[activeTab],
                                                        dataTable: {
                                                            ...prevData[activeTab]?.dataTable,
                                                            data: newData,
                                                        },
                                                    },
                                                };
                                                return updatedData;
                                            });
                                        }}
                                    />
                                ) : (
                                    data?.id === "customDimensions" ? (
                                        <LabelTable
                                            initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                            onLabelsChange={(labels: any) => {
                                                setData((prevData: dataMatchingToNavBar | null) => {
                                                    if (!prevData || !prevData[activeTab]) return prevData;

                                                    const updatedData = {
                                                        ...prevData,
                                                        [activeTab]: {
                                                            ...prevData[activeTab],
                                                            dataTable: {
                                                                ...prevData[activeTab]?.dataTable,
                                                                data: labels,
                                                            },
                                                        },
                                                    };
                                                    return updatedData;
                                                });
                                            }}
                                            fieldType='extensionElement'
                                        />

                                    ) :
                                        <GenericGridTable
                                            type="dimension"
                                            data={data?.[activeTab]?.dataTable?.data || []}
                                            setData={(newData: cDimensionValueReference[]) => {
                                                setData((prevData: dataMatchingToNavBar | null) => {
                                                    if (!prevData || !prevData[activeTab]) return prevData;

                                                    const updatedData = {
                                                        ...prevData,
                                                        [activeTab]: {
                                                            ...prevData[activeTab],
                                                            dataTable: {
                                                                ...prevData[activeTab]?.dataTable,
                                                                data: newData,
                                                            },
                                                        },
                                                    };
                                                    return updatedData;
                                                });
                                            }}
                                        />
                                )
                            )}
                            {(activeTab === "childDimensionValues" || activeTab === "childElements") && (
                                <GenericGridTable
                                    type="childDimensionValues"
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    setData={(newData: cDimensionValueReference[]) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                        </>
                    )}
                    {(activeTab === "valueLabel") && (
                        <SelectTable
                            data={data?.[activeTab]?.data || []}
                            setData={(newData: selectTableProps[]) => {
                                setData((prevData: dataMatchingToNavBar | null) => {
                                    if (!prevData) return prevData;

                                    const updatedData = {
                                        ...prevData,
                                        valueLabel: {
                                            navBarName: t`Value Labels`,
                                            data: newData,
                                        },
                                    };
                                    return updatedData;
                                });
                            }}
                        />
                    )}
                    {pagesShouldNotBeMapped.includes(activeTab) && (
                        <>
                            {(activeTab === "extension" || activeTab === "extensionStructure") && (
                                <>
                                    {data?.id === "tags" ? (
                                        <GenericGridTable
                                            type={"basicList"}
                                            data={data?.[activeTab]?.additionalElements || []}
                                            setData={(newData: cDimensionValueReference[]) => {
                                                setData((prevData: dataMatchingToNavBar | null) => {
                                                    if (!prevData || !prevData[activeTab]) return prevData;

                                                    const updatedData = {
                                                        ...prevData,
                                                        [activeTab]: {
                                                            ...prevData[activeTab],
                                                            dataTable: {
                                                                ...prevData[activeTab]?.dataTable,
                                                                data: newData,
                                                            },
                                                        },
                                                    };
                                                    return updatedData;
                                                });
                                            }}
                                            header={activeTab}
                                        />
                                    ) : (
                                        <GenericGridTable
                                            type="onlyDimension"
                                            data={data?.[activeTab]?.dataTable?.data || []}
                                            setData={(newData: cDimensionValueReference[]) => {
                                                setData((prevData: dataMatchingToNavBar | null) => {
                                                    if (!prevData || !prevData[activeTab]) return prevData;

                                                    const updatedData = {
                                                        ...prevData,
                                                        [activeTab]: {
                                                            ...prevData[activeTab],
                                                            dataTable: {
                                                                ...prevData[activeTab]?.dataTable,
                                                                data: newData,
                                                            },
                                                        },
                                                    };
                                                    return updatedData;
                                                });
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {activeTab === "taxonomies" && (
                                <SelectTaxonomies
                                    isDialog={false}
                                    open={true}
                                    onClose={() => { }}
                                    onApply={handleTaxonomySetter}
                                />
                            )}
                        </>
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (activeTab === "additionalElements") && (
                        <GenericGridTable
                            type={"element"}
                            data={data?.[activeTab]?.dataTable?.data || []}
                            setData={(newData: cDimensionValueReference[]) => {
                                setData((prevData: dataMatchingToNavBar | null) => {
                                    if (!prevData || !prevData[activeTab]) return prevData;

                                    const updatedData = {
                                        ...prevData,
                                        [activeTab]: {
                                            ...prevData[activeTab],
                                            dataTable: {
                                                ...prevData[activeTab]?.dataTable,
                                                data: newData,
                                            },
                                        },
                                    };
                                    return updatedData;
                                });
                            }}
                        />
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (activeTab === "dimensionValues") && (
                        <>
                            {data?.id === "tags" && (
                                <GenericGridTable
                                    type={"tagsDimensionValues"}
                                    data={data?.dimensionValues?.additionalElements || []}
                                    setData={(newData: cDimensionValueReference[]) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;
                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                            {data?.id === "labels" && (
                                <GenericGridTable
                                    type={"labelDimensionValues"}
                                    data={data?.dimensionValues?.dataTable?.data || []}
                                    setData={(newData: cDimensionValueReference[]) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                dimensionValues: {
                                                    ...prevData.dimensionValues,
                                                    dataTable: {
                                                        ...prevData.dimensionValues?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                            {data?.id === "header" && (
                                <GenericGridTable
                                    type={"headerDimensionValues"}
                                    data={data?.[activeTab]?.additionalElements || []}
                                    setData={(newData: cDimensionValueReference[]) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                            {data?.id !== "tags" && data?.id !== "labels" && data?.id !== "header" && (
                                <GenericGridTable
                                    type={"contextDimensionValues"}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    setData={(newData: cDimensionValueReference[]) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                        </>
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (activeTab === "documentation" || activeTab === "labels") && (
                        <>
                            {data?.id === "extensionElements" && activeTab === "labels" ? (
                                <LabelTable
                                    initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                    onLabelsChange={(labels: any) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: labels,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                    fieldType='extensionElement'
                                />
                            ) : activeTab === "documentation" || data?.id === "settings" ? (
                                <LabelTable
                                    initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                    onLabelsChange={(labels: any) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: labels,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                    fieldType='documentation'
                                />
                            ) : (
                                <LabelTable
                                    initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                    onLabelsChange={(labels: any) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: labels,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                    fieldType='default'
                                />
                            )}
                        </>
                    )}

                    {!pagesShouldNotBeMapped.includes(activeTab) && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {Object.entries(data?.[activeTab] || {})
                                .filter(([key, item]: [string, any]) => {
                                    const isValidItem = (item: any) =>
                                        !(item.key === "" && item.name === "" && item.value === null);
                                    return item !== undefined && isValidItem(item);
                                })
                                .map(([key, item]: [string, any], index: number) => (
                                    key !== 'navBarName' &&
                                    key !== undefined && (
                                        <Grid item xs={12} key={key + index}>
                                            <LabeledTextField
                                                endpointUrl={item?.endpointUrl || undefined}
                                                label={item?.name || ""}
                                                placeholder={item?.placeholder || ""}
                                                options={item?.options || []}
                                                fieldType={item?.fieldType}
                                                value={item?.value}
                                                onValueChange={(event: any) => handleChange(event, key)}
                                            />
                                        </Grid>
                                    )
                                ))}
                        </Box>
                    )}
                </DialogContent>
                <Box display="flex" justifyContent="flex-end" m={3}>
                    <Container
                        maxWidth={"xl"}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid>
                            {id && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        border: "1px solid #d32f2f",
                                        backgroundColor: "#d32f2f",
                                        ':hover': {
                                            backgroundColor: "#b71c1c"
                                        }
                                    }}
                                    onClick={handleOpenDeleteDialog}
                                >
                                    {t`Delete`}
                                </Button>
                            )}
                        </Grid>
                        <Grid>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t`Close`}
                            </Button>
                            <Button sx={{ ml: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={handleApply}
                            >
                                {t`Apply`}
                            </Button>
                        </Grid>
                    </Container>
                </Box>
            </Dialog>
        </>
    );
};

export default CustomObjectsDialog;
